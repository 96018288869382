.imgLogo {
    width: 75%;
    max-width: 195px;
    height: auto;
    border-radius: 0%;
    margin-top: 0;
    vertical-align: super;
    position: absolute;
    top: -35px;
    left: 16px;
}

/* Float the link section to the right */
.header-right {
    float: right;
}

/* .social-right {
    display: flex;
    margin-left: auto;
    margin-right: auto;
} */

/* .header {
    overflow: hidden;
    padding: 5px 10px;
  } */

.navbar {
    background: lightgray;
    height: 150px;
    font-size: 1.2rem;
    z-index: 1;
    width:100%;
}

.navbar-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(40, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-content: center;
    z-index: 1;
}

.nav-item {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
}

.nav-item:hover {
    /* background-color: #fff; */
    background: #41b0ff;
    color: white;
}

.nav-links {
    /* color: #3498DB; */
    background-color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    color: blue;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.nav-links:active {
    /* background-color: #852B91; */
    color: #fff;
    background: #852B91;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.top-center {
    margin: auto;
}

.contactBtn {
    font-size: large;
    color: #3498DB;
}

.contactBtn:hover {
    color: #fff;
    background-color: #3498DB;
}

.contactText {
    font-size: 12px;
}

/* main{
    padding-top: 20px; 
  } */

/* @media screen and (max-width: 1200px) {

    .contactBtn {
        font-size: small;
        color: #3498DB;
    }

    .contactBtn:hover {
        color: #fff;
        background-color: #3498DB;
    }

    .contactText {
        font-size: x-small;
    }

} */

@media screen and (max-width: 1320px) {
    .NavbarItems {
      position: relative;
    }

    .social-right {
      display: flexbox;
    }

    main{
      padding-top: 0; 
    }

    .contactBtn{
      font-size: small;
      color: #3498DB;
    }

    .contactBtn:hover{
      color: #fff;
      background-color: #3498DB;
    }

    .contactText{
      font-size: x-small;
    }
  
    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 50vh;
      color: white;
    }
  
    .nav-menu.active {
      display: flex;
      background: #2d649b;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
 
    .nav-item {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-item:hover {
      background-color: #1888ff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      /* transform: translate(25%, 50%); */
      width: 50%;
      height: auto;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      left: 75%;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      /* width: 12.25px;
      height: 37.79px;
      color: white; */
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      background-color: white;
      color: #3498DB;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      color: blue;
      transition: 250ms;
    }
  
    /* button {
      display: none;
    } */
  }
  
  /* @media screen and (max-width: 768px) {

  
    .contactBtn{
      font-size: xx-small;
      color: #3498DB;
    }
  
    .contactBtn:hover{
      color: #fff;
      background-color: #3498DB;
    }
  
    .contactText{
      display: none;
    }
  
} */