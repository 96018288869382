/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;

  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  text-align: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

#error-content {
  text-align: center;
}

section {
  margin: 1rem auto;
  width: 90%;
  /* max-width: 40rem; */
  text-align: left;
  background-color: rgba(255, 255, 255, 0.596);
  padding: 2rem;
  border-radius: 12px;
}

button {
  font: inherit;
  cursor: pointer;
  background: #3498DB;
  border: 1px solid #3498DB;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 20px;
}

button:focus {
  outline: none;
}

button:hover,
button:active {
  background-color: #4aa0f5;
  border-color: #2770ba;
}

main {
  background-color: #fff;
}

.webviewer {
  flex: 1;
  margin: 8px;
  height: 70vh;
  -webkit-box-shadow: 1px 1px 10px #999;
          box-shadow: 1px 1px 10px #999;
}
