.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .videosection {
    max-width: auto;
    margin: 2rem auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    /* border-radius: 12px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  }